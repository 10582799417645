import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { CollectionIcon } from "../icons/CustomIcons";

const Wrapper = styled.div<{ $large: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex: none;

  ${({ $large, theme }) =>
    $large &&
    css`
      width: 28px;
      height: 28px;
      box-shadow: inset 0 0 0 1px ${theme.borderColor.DEFAULT};
      border-radius: ${theme.borderRadius};
    `}
`;

const EmojiWrapper = styled.div`
  font-size: 15px;
`;

export interface CollectionAvatarProps {
  emoji?: ReactNode;
  large?: boolean;
}

export const CollectionAvatar: React.ComponentType<CollectionAvatarProps> =
  React.memo(function CollectionAvatar({
    emoji,
    large = false,
  }: CollectionAvatarProps) {
    return (
      <Wrapper $large={large}>
        {emoji ? <EmojiWrapper>{emoji}</EmojiWrapper> : <CollectionIcon />}
      </Wrapper>
    );
  });
